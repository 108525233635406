<template>
  <Panel :title="title" v-if="contact" :is-collapsible="false">
    <span slot="toolbar-buttons">
      <a class="btn btn-xs btn-primary" @click="newLink">
        <i class="fa fa-plus" /> Nieuw
      </a>
    </span>
    <Datagrid
      :columns="columns"
      :data="contactLinks"
      :actions="actions"
      @delete="deleteLinkHandler"
      @edit="editLink"
    />
    <ContactLinkModal ref="modal" />
  </Panel>
</template>

<script>
import Vue from 'vue'
import Panel from '@/components/iam/Panel'
import ContactLinkModal from '@/components/contacts/ContactLinkModal'
import Datagrid from '@/components/iam/DataGrid'

import { mapActions, mapState } from 'vuex'
import { errorModal, questionModal, successModal } from '@/modalMessages'

Vue.component('link-type', {
  props: ['value'],
  template: '<span> {{ value.name }}</span>'
})

Vue.component('link-contact', {
  props: ['value'],
  template: `<router-link :to="{name: \'ContactDetails\', params: {id: value.id}}" v-if="value">
               {{ value.display_name }}</router-link>`
})

export default {
  name: 'ContactLinkPanel',
  components: {
    ContactLinkModal,
    Panel,
    Datagrid
  },
  props: {
    contact: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      id: 'ContactCommunicationList',
      columns: [
        { key: 'type', title: 'Relatietype', renderComponent: 'link-type' },
        { key: 'to_relation', title: 'Contact', renderComponent: 'link-contact' },
        { key: 'notes', title: 'Opmerkingen' }
      ],
      actions: [
        {
          key: 'edit',
          type: 'success',
          icon: 'pencil',
          title: 'Link bewerken'
        },
        {
          key: 'delete',
          type: 'danger',
          icon: 'trash',
          title: 'Link verwijderen'
        }
      ]
    }
  },
  computed: {
    ...mapState('contacts', ['contactLinks']),

    contactId () {
      return this.$route.params.id
    },
    title () {
      return `Relaties voor ${this.contact.display_name}`
    }
  },
  watch: {
    contactId: {
      immediate: true,
      handler (value) {
        this.loadLinksForContactById(value)
      }
    }
  },
  methods: {
    ...mapActions('contacts', ['loadLinksForContactById', 'deleteLink']),

    newLink () {
      this.$refs.modal.newLink()
    },
    editLink (link) {
      return this.$refs.modal.editLink(link)
    },
    async deleteLinkHandler ({ id, from_relation }) {
      try {
        const result = await questionModal('Weet je zeker dat je deze relatie wil verwijderen? Let op: de inverse relatie zal ook verwijderd worden.')
        if (!result.value) return

        const response = await this.deleteLink({ id, contactId: from_relation?.id })
        successModal('De link is succesvol verwijderd.')
        return response
      } catch (error) {
        errorModal('Kan link niet verwijderen gelieve opnieuw te proberen')
      }
    }
  }
}
</script>
