<template>
  <BaseModal title="Verzonden HMODH-e-mails" ref="modal" max-width="tw-max-w-2xl" content-padding="tw-px-6">
    <div class="tw-overflow-auto">
      <ul>
        <li
          v-for="mailing in mailings.results"
          :key="mailing.id"
          class="tw-p-4 tw-pr-0 tw-border-l tw-border-gray-cc tw-flex tw-flex-row tw-ml-2"
        >
          <div
            :class="[
              'tw-h-4 tw-w-4 tw--ml-6 tw-mr-2 md:tw-mr-4 tw-my-auto tw-rounded-full tw-flex-shrink-0',
              mailing.opened_on ? 'tw-bg-primary' : 'tw-bg-gray-e9'
            ]"
          />
          <div class="tw-flex-grow">
            <div class="tw-mb-2 tw-flex tw-flex-wrap tw-justify-between tw-items-baseline">
              <div class="tw-flex-grow">
                <h2 class="tw-mb-0 tw-inline tw-text-base tw-font-normal">
                  {{ mailing.created_on | date-day }}
                </h2>
              </div>
              <span
                v-if="mailing.opened_on"
                class="tw-rounded tw-px-2 tw-bg-gray-e9"
              >
                {{ `Gelezen op ${formatDateNlBe(mailing.opened_on, 'dd-MM-yyyy HH:mm')}` }}
              </span>
            </div>
            <div
              class="tw-flex tw-flex-wrap tw-gap-2"
            >
              <router-link
                v-for="(hmodhProperty, index) in mailing.hmodh_properties"
                :key="index"
                :to="{ name: 'PropertyDetails', params: { id: hmodhProperty.property.id } }"
                :class="[
                  'tw-border tw-border-1 tw-rounded tw-px-2',
                  { 'tw-bg-primary tw-text-white hover:tw-text-white': hmodhProperty.click_data }
                ]"
                :title="
                  hmodhProperty.click_data ? `Pand laatst bekeken op ${formatDateNlBe(hmodhProperty.click_data.clicked_on, 'dd-MM-yyyy HH:mm')} (${hmodhProperty.click_data.count}x bekeken)`
                  : ''
                "
              >
                {{ hmodhProperty.property.reference }}
              </router-link>
            </div>
          </div>
        </li>
      </ul>
      <InfiniteLoading :identifier="identifier" key="infiniteLoader" spinner="waveDots" @infinite="infiniteLoad">
        <div slot="no-more"><!-- Empty div to not render anything --></div>
        <template #no-results>Geen verzonden e-mails voor deze HMODH</template>
        <template #error="{ trigger }">
          Fout bij laden van de verzonden e-mails,
          <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
            probeer het opnieuw.
          </button>
        </template>
      </InfiniteLoading>
    </div>
  </BaseModal>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import InfiniteLoading from 'vue-infinite-loading'

import { formatDateNlBe } from '@/utils/helpers'
import { getHmodhMailings } from '@/services/hmodh'

export default {
  name: 'HmodhMailings',
  components: {
    InfiniteLoading
  },
  data () {
    return {
      identifier: uniqueId('mailings_'),
      mailings: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  methods: {
    formatDateNlBe,
    async loadMailings (payload) {
      try {
        const response = await getHmodhMailings(payload)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
    async show (hmodhId) {
      const mailings = await this.loadMailings({ hmodhId })
      this.mailings = mailings
      this.$refs.modal.show()
      return mailings
    },
    async infiniteLoad ($infinite) {
      try {
        const next = this.mailings.next
        if (!next) {
          // No more data and state is loaded
          if (this.mailings.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const mailings = await this.loadMailings({ url: next })
        const results = [...this.mailings.results, ...mailings.results]
        this.mailings = { ...mailings, results }
        $infinite.loaded()
        return mailings
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    }
  }
}
</script>
