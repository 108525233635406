var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Panel',{attrs:{"is-collapsible":false,"title":"Zoekopdrachten"}},[_c('div',{attrs:{"slot":"toolbar-buttons"},slot:"toolbar-buttons"},[_c('button',{staticClass:"tw-ml-2 btn btn-primary btn-xs",attrs:{"type":"button","title":_vm.hmodhCreationButtonTitle,"disabled":_vm.hmodhCreationDisabled},on:{"click":_vm.newHmodh}},[_c('i',{staticClass:"glyphicon glyphicon-plus-sign"}),_vm._v(" Nieuw ")])]),_c('DataTable',_vm._b({staticClass:"tw--m-5 tw-shadow-none",attrs:{"headers":_vm.headers,"can-search":true,"infinite-scroll":true,"infinite-key":_vm.identifier},on:{"infiniteLoad":_vm.infiniteLoad,"filter":_vm.refreshHmodhs,"changedOrder":_vm.refreshHmodhs},scopedSlots:_vm._u([{key:"item-active_status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['fa fa-circle', _vm.isHmodhActive(item) ? 'tw-text-green-500' : _vm.isHmodhArchived(item) ? 'tw-text-error' : 'tw-text-warning'],attrs:{"title":_vm.getArchivalInformation(item)}})]}},{key:"item-status_display",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_display)+" ")]}},{key:"item-created_by",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_by ? ((item.created_by.first_name) + " " + (item.created_by.last_name)) : 'Aangemaakt door contact via klantenportaal')+" ")])]}},{key:"item-niches",fn:function(ref){
var item = ref.item;
return _vm._l((item.niches),function(niche_id,index){return _c('span',{key:niche_id},[(index > 0)?[_vm._v(" | ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.NICHES[niche_id])+" ")],2)})}},{key:"item-properties_refreshed_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.properties_refreshed_on))+" ")]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[(_vm.isHmodhActive(item))?_c('button',{staticClass:"action tw-bg-warning",attrs:{"title":"Bewerk HMODH"},on:{"click":function($event){return _vm.editHmodh(item)}}},[_c('i',{class:[_vm.loadingHmodhDetails ? 'fa fa-spinner fa-spin' : 'far fa-pencil']})]):_vm._e(),(!_vm.isHmodhAwaitingActivation(item))?_c('button',{staticClass:"action tw-bg-success",attrs:{"title":"Verzonden HMODH's"},on:{"click":function($event){return _vm.showHmodhMailings(item)}}},[_c('i',{class:[_vm.loadingHmodhMailings === item.id ? 'fa fa-spinner fa-spin' : 'far fa-envelope']})]):_vm._e(),(_vm.isHmodhActive(item))?_c('button',{staticClass:"action tw-bg-primary",attrs:{"title":"Resterende panden"},on:{"click":function($event){return _vm.showHmodhProperties(item)}}},[_c('i',{staticClass:"far fa-eye"})]):_vm._e(),(!_vm.isHmodhAwaitingActivation(item))?_c('button',{class:['action', _vm.isHmodhActive(item) ? 'tw-bg-error' : 'tw-bg-primary'],attrs:{"title":_vm.isHmodhActive(item) ? 'Archiveren' : 'Heractiveren'},on:{"click":function($event){return _vm.toggleHmodh(item)}}},[_c('i',{class:[_vm.isHmodhActive(item) ? 'far fa-archive' : 'fa fa-rotate-left']})]):_vm._e()])]}}])},'DataTable',_vm.hmodhs,false)),_c('HmodhEditCreate',{ref:"editCreate",attrs:{"contact":_vm.contact},on:{"hmodhCreated":_vm.refreshHmodhs,"hmodhUpdated":_vm.refreshHmodhs}}),_c('HmodhPropertiesModal',{ref:"hmodhPropertiesModal"}),_c('HmodhMailings',{ref:"mailingsModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }