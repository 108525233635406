import { render, staticRenderFns } from "./ContactTagManager.vue?vue&type=template&id=6d20f61b&scoped=true&"
import script from "./ContactTagManager.vue?vue&type=script&lang=js&"
export * from "./ContactTagManager.vue?vue&type=script&lang=js&"
import style0 from "./ContactTagManager.vue?vue&type=style&index=0&id=6d20f61b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d20f61b",
  null
  
)

export default component.exports