var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Verzonden HMODH-e-mails","max-width":"tw-max-w-2xl","content-padding":"tw-px-6"}},[_c('div',{staticClass:"tw-overflow-auto"},[_c('ul',_vm._l((_vm.mailings.results),function(mailing){return _c('li',{key:mailing.id,staticClass:"tw-p-4 tw-pr-0 tw-border-l tw-border-gray-cc tw-flex tw-flex-row tw-ml-2"},[_c('div',{class:[
            'tw-h-4 tw-w-4 tw--ml-6 tw-mr-2 md:tw-mr-4 tw-my-auto tw-rounded-full tw-flex-shrink-0',
            mailing.opened_on ? 'tw-bg-primary' : 'tw-bg-gray-e9'
          ]}),_c('div',{staticClass:"tw-flex-grow"},[_c('div',{staticClass:"tw-mb-2 tw-flex tw-flex-wrap tw-justify-between tw-items-baseline"},[_c('div',{staticClass:"tw-flex-grow"},[_c('h2',{staticClass:"tw-mb-0 tw-inline tw-text-base tw-font-normal"},[_vm._v(" "+_vm._s(_vm._f("date-day")(mailing.created_on))+" ")])]),(mailing.opened_on)?_c('span',{staticClass:"tw-rounded tw-px-2 tw-bg-gray-e9"},[_vm._v(" "+_vm._s(("Gelezen op " + (_vm.formatDateNlBe(mailing.opened_on, 'dd-MM-yyyy HH:mm'))))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-2"},_vm._l((mailing.hmodh_properties),function(hmodhProperty,index){return _c('router-link',{key:index,class:[
                'tw-border tw-border-1 tw-rounded tw-px-2',
                { 'tw-bg-primary tw-text-white hover:tw-text-white': hmodhProperty.click_data }
              ],attrs:{"to":{ name: 'PropertyDetails', params: { id: hmodhProperty.property.id } },"title":hmodhProperty.click_data ? ("Pand laatst bekeken op " + (_vm.formatDateNlBe(hmodhProperty.click_data.clicked_on, 'dd-MM-yyyy HH:mm')) + " (" + (hmodhProperty.click_data.count) + "x bekeken)")
                : ''}},[_vm._v(" "+_vm._s(hmodhProperty.property.reference)+" ")])}),1)])])}),0),_c('InfiniteLoading',{key:"infiniteLoader",attrs:{"identifier":_vm.identifier,"spinner":"waveDots"},on:{"infinite":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_vm._v("Geen verzonden e-mails voor deze HMODH")]},proxy:true},{key:"error",fn:function(ref){
                var trigger = ref.trigger;
return [_vm._v(" Fout bij laden van de verzonden e-mails, "),_c('button',{staticClass:"link tw-font-semibold",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return trigger.apply(null, arguments)}}},[_vm._v(" probeer het opnieuw. ")])]}}])},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }