<template>
  <div v-if="contact">
    <div class="row">
      <div class="col-md-4">
        <dl class="dl-horizontal">
          <dt>Type</dt>
          <dd v-if="contact.type === 'P'">Persoon</dd>
          <dd v-if="contact.type === 'B'">Bedrijf</dd>

          <template v-if="contact.type === 'P'">
            <dt>Voornaam</dt>
            <dd>{{ contact.first_name }}</dd>
            <dt>Naam</dt>
            <dd>{{ contact.last_name }}</dd>
          </template>

          <template v-if="contact.type === 'B'">
            <dt>Bedrijfsnaam</dt>
            <dd>{{ contact.company_name }}</dd>
            <dt>Bedrijfstype</dt>
            <dd v-if="contact.type === 'B'">{{ contact.company_type_display }}</dd>
          </template>

          <dt>Voorkeurstaal</dt>
          <dd v-if="contact.language === 'nl'">Nederlands</dd>
          <dd v-if="contact.language === 'fr'">Frans</dd>
          <dd v-if="contact.language === 'en'">Engels</dd>

          <template v-if="contact.type === 'P'">
            <dt>Geslacht</dt>
            <dd v-if="contact.gender === 'M'">Man</dd>
            <dd v-if="contact.gender === 'F'">Vrouw</dd>
            <dd v-if="contact.gender === 'U'">Onbekend</dd>
          </template>

          <dt>Rijksregisternummer</dt>
          <dd>{{ contact.national_registry_number || '-' }}</dd>

          <dt>Burgerlijke staat</dt>
          <dd>{{ contact.marital_status_display || '-' }}</dd>

          <dt>Gearchiveerd</dt>
          <dd>
            <span :class="['tw-px-1.5 tw-py-0.5 tw-rounded tw-text-white', contact.is_archived ? 'tw-bg-danger' : 'tw-bg-primary']">
              {{ contact.is_archived ? 'Ja' : 'Nee' }}
            </span>
          </dd>
        </dl>
      </div>

      <div class="col-md-4">
        <dl class="dl-horizontal">
          <dt>Adres</dt>
          <dd>
            <span v-if="contact.street || contact.number || contact.box">
              {{ contact.street }} {{ contact.number }} {{ contact.box }}
            </span>
            <span v-else>-</span>
          </dd>

          <dt>Woonplaats</dt>
          <dd>{{ contact.city ? `${contact.city.zip_code} ${contact.city.name}` : '-' }}</dd>

          <dt>Land</dt>
          <dd>
            <Country v-if="contact.city" :city="contact.city" :type="'name'" />
            <span v-else>-</span>
          </dd>

          <dt>Geboortedatum</dt>
          <dd>
            <span v-if="contact.birth_date">
              {{ contact.birth_date | date-day }}
            </span>
            <span v-else>-</span>
          </dd>

          <dt>Geboorteplaats</dt>
          <dd>{{ birthPlace || '-' }}</dd>

          <dt>Rekeningnummer</dt>
          <dd>{{ contact.bank_account_number || '-' }}</dd>

          <dt>Ondernemingsnummer</dt>
          <dd>
            {{ contact.vat || '-' }}
            <span v-if="contact.vat">
              {{ contact.is_vat_liable ? '(btw-plichtig)' : '(niet btw-plichtig)' }}
            </span>
            <ContactVatViesChecker
              v-if="contact.vat"
              :contact="contact"
            />
          </dd>
        </dl>
      </div>
      <div class="col-md-4">
        <dl class="dl-horizontal">
          <dt>Website</dt>
          <dd>
            <a v-if="contact.website" :href="contact.website" target="_blank">{{ contact.website }}</a>
            <span v-else>-</span>
          </dd>
          <dt>Telefoon</dt>
          <dd>
            <a v-if="contact.phone" @click="contactCall(contact.phone)">{{ contact.phone }}</a>
            <span v-else>-</span>
          </dd>
          <dt>Gsm</dt>
          <dd>
            <a v-if="contact.mobile" @click="contactCall(contact.mobile)">{{ contact.mobile }}</a>
            <span v-else>-</span>
          </dd>
          <dt>E-mail</dt>
          <dd>
            <Email v-if="contact.email" :contact="contact" type="candidate" />
            <span v-else>-</span>
          </dd>

          <dt>
            Klantenportaal
          </dt>
          <dd class="tw-flex tw-space-x-1">
            <div v-if="activating">
              <i class="fa fa-spinner fa-spin tw-mr-2" />
              <span>Bezig met activeren ...</span>
            </div>
            <button
              v-else
              :disabled="!contact.email"
              type="button"
              :title="activateCustomerPortalButtonTitle"
              :class="['btn btn-xs', isPortalUser ? 'btn-success  tw-pointer-events-none' : 'btn-warning' ]"
              @click="startPortalActivation"
            >
              {{ isPortalUser ? 'Actief' : 'Activeren' }}
            </button>
            <button v-if="isPortalUser" type="button" class="btn btn-xs btn-info" @click="showLoginHistory">
              Log
            </button>
          </dd>

          <template v-if="isPortalUser">
            <dt>Laatste logintijd</dt>
            <dd>{{ getLastLoginTime(portalUser.last_login_on) }}</dd>
          </template>

          <dt style="margin-top:5px">GDPR</dt>
          <dd style="margin-top:5px">
            <GDPR :contact="contact" :text="'GDPR'" ref="gdpr" />
            <button
              v-if="showSetGdprGreenButton"
              :disabled="settingGdprGreen"
              class="btn btn-default btn-xs tw-ml-1"
              @click="setGdprGreen"
            >
              Contact op groen zetten
            </button>
          </dd>

          <dt style="margin-top:5px">Duplicate contacten</dt>
          <dd style="margin-top:5px">
            <ContactDuplicateCounter :contact="contact" />
          </dd>
        </dl>
      </div>
    </div>
    <div class="tw-mb-4">
      <p>
        <small v-if="contact.created_on">
          Aangemaakt op {{ contact.created_on | datetime }}
          <span v-if="contact.created_by">
            door {{ contact.created_by.first_name }} {{ contact.created_by.last_name }}
          </span>
        </small>
        <br>
        <small v-if="contact.updated_on">
          Gewijzigd op {{ contact.updated_on | datetime }}
          <span v-if="contact.updated_by">door
            {{ contact.updated_by.first_name }} {{ contact.updated_by.last_name }}
          </span>
        </small>
      </p>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <HmodhPanel :contact="contact" />
      </div>
    </div>

    <div class="row">
      <!--panel contact link -->
      <div class="col-sm-6">
        <ContactLinkPanel :contact="contact" />
      </div>
      <div class="col-sm-6">
        <ContactTagManager :contact-id="contact.id" />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Panel :is-collapsible="false" title="Samenwerkingsvoorwaarden">
          <span slot="toolbar-buttons">
            <a v-if="!editCooperationConditions" class="btn btn-xs btn-primary" @click="editCooperationConditions = true" >
              <i class="fa fa-pencil" /> Bewerken
            </a>
            <a v-if="editCooperationConditions" class="btn btn-xs btn-primary" @click="saveCooperationConditions">
              <i class="glyphicon glyphicon-ok" /> Opslaan
            </a>
          </span>
          <div>
            <div v-show="!editCooperationConditions">
              <pre>{{ cooperationConditions }}</pre>
            </div>
            <div v-show="editCooperationConditions">
              <textarea class="form-control" ref="noteContent" rows="5" v-model="cooperationConditions" />
            </div>
          </div>
        </Panel>
        <span class="tw-text-[0.6rem] tw-italic">{{ contact.uuid }}</span>
      </div>
      <!--panel commentaar-->
      <div class="col-sm-6">
        <Panel :is-collapsible="false" title="Commentaar">
          <span slot="toolbar-buttons">
            <a v-if="!editNotes" class="btn btn-xs btn-primary" @click="editNotes = true" >
              <i class="fa fa-pencil" /> Bewerken
            </a>
            <a v-if="editNotes" class="btn btn-xs btn-primary" @click="saveNotes">
              <i class="glyphicon glyphicon-ok" /> Opslaan
            </a>
          </span>
          <div>
            <div v-show="!editNotes">
              <pre>{{ notes }}</pre>
            </div>
            <div v-show="editNotes">
              <textarea class="form-control" ref="noteContent" rows="5" v-model="notes" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
    <Modal ref="portalLoginHistory" title="Logingeschiedenis klantenportaal">
      <DataTable
        :loading="loadingLoginHistory"
        :headers="headers"
        v-bind="loginHistory"
        class="tw--m-5 tw-shadow-none"
        @changePage="loadLoginHistory"
      >
        <template #item-date="{ item }">
          {{ item.date | datetime }}
        </template>
      </DataTable>
    </Modal>
  </div>
</template>

<script>
import { format } from 'date-fns'

import DataTable from '@/components/DataTable'
import Modal from '@/components/iam/Modal'
import Panel from '@/components/iam/Panel'
import Email from '@/components/iam/Email'
import Country from '@/components/iam/Country'
import GDPR from '@/components/iam/gdprComponent'

import HmodhPanel from '@/components/contacts/hmodh/HmodhPanel'
import ContactDuplicateCounter from '@/components/contacts/ContactDuplicateCounter'
import ContactLinkPanel from '@/components/contacts/ContactLinkPanel'
import ContactTagManager from '@/components/contacts/ContactTagManager'
import ContactVatViesChecker from '@/components/contacts/ContactVatViesChecker'

import { errorModal, successModal } from '@/modalMessages'
import { getContactVies, getPortalUser, getContactPortalLogins, activatePortal } from '@/services/contacts'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ContactGeneral',
  components: {
    Modal,
    HmodhPanel,
    Panel,
    GDPR,
    Email,
    ContactLinkPanel,
    Country,
    ContactDuplicateCounter,
    DataTable,
    ContactTagManager,
    ContactVatViesChecker
  },
  data () {
    return {
      editNotes: false,
      notes: '',
      editCooperationConditions: false,
      cooperationConditions: '',
      portalUser: null,
      loadingLoginHistory: false,
      // The object loginHistory includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      loginHistory: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      activating: false,
      settingGdprGreen: false,
      viesData: null,
      viesDataLoading: false
    }
  },
  computed: {
    ...mapState('contacts', ['contact']),
    ...mapState('account', ['user']),
    contactId () {
      return this.$route.params.id
    },
    birthPlace () {
      return this.contact.birth_place?.name ?? ''
    },
    isPortalUser () {
      return this.portalUser?.user
    },
    headers () {
      return [
        { text: 'Datum en tijd', value: 'date' }
      ]
    },
    activateCustomerPortalButtonTitle () {
      if (!this.contact.email) {
        return 'Voor het activeren van het klantenportaal is een emailadres vereist'
      }
      return this.isPortalUser ? 'Klantenportaal is actief' : 'Klik om het klantenportaal te activeren'
    },
    showSetGdprGreenButton () {
      // Only allowed for superusers!
      return this.contact.global_unsubscribe && this.user.is_superuser
    }
  },
  created () {
    this.init()
  },
  methods: {
    format,
    ...mapActions('contacts', ['saveContact']),
    ...mapMutations('contacts', ['setContactConsentAsked', 'setContactGlobalUnsubscribe']),

    async init () {
      const response = await this.loadPortalUser(this.contactId)
      this.notes = this.contact?.notes
      this.cooperationConditions = this.contact?.cooperation_conditions
      return response
    },
    showLoginHistory () {
      this.loadLoginHistory()
      this.$refs.portalLoginHistory.show()
    },
    getLastLoginTime (last_login_time) {
      return last_login_time
        ? format(new Date(last_login_time), 'dd-MM-yyyy HH:mm')
        : 'Deze portaalgebruiker heeft nog niet ingelogd.'
    },
    contactCall (target) {
      if (target) {
        const win = window.open(`tel:${target}`, '_blank')
        if (!win) errorModal('Sta pop-ups toe om het eigenaarsrapport te zien.')
      } else {
        return errorModal('Geen nummer om te contacteren.')
      }
      this.$router.push({ name: 'ContactLeads', params: { quickLeadActivityType: 1 } })
    },
    async saveNotes () {
      this.editNotes = false
      try {
        const uploadContact = {
          id: this.contact.id,
          notes: this.notes
        }
        const response = await this.saveContact(uploadContact)
        this.notes = response.notes
        return response
      } catch {
        errorModal('Kan notitie niet aanpassen, gelieve opnieuw te proberen.')
      }
    },
    async saveCooperationConditions () {
      this.editCooperationConditions = false
      try {
        const uploadContact = {
          id: this.contact.id,
          cooperation_conditions: this.cooperationConditions
        }
        const response = await this.saveContact(uploadContact)
        this.cooperationConditions = response.cooperation_conditions
        return response
      } catch {
        errorModal('Samenwerkingsvoorwaarden kunnen niet worden opgeslagen, probeer het opnieuw.')
      }
    },
    async loadPortalUser (contactId) {
      try {
        const response = await getPortalUser(contactId)
        this.portalUser = response.data
        return response
      } catch {
        return errorModal('Er ging iets mis bij het ophalen van de portaalinformatie voor dit contact.')
      }
    },
    async loadLoginHistory (payload) {
      try {
        this.loadingLoginHistory = true
        const response = await getContactPortalLogins({ contactId: this.contactId, ...payload })
        this.loginHistory = response.data
        this.loadingLoginHistory = false
        return response
      } catch (error) {
        return errorModal('Fout bij het laden van de logingeschiedenis klantenportaal.')
      }
    },
    async startPortalActivation () {
      try {
        this.activating = true
        const payload = {
          contact_id: this.contactId
        }
        const response = await activatePortal(payload)
        await this.loadPortalUser(this.contactId)
        this.activating = false
        successModal('Er werd een email verstuurd voor het activeren van het klantenportaal')
        return response
      } catch (error) {
        const errorCodes = {
          USER_ALREADY_EXISTS: 'Er werd al een klantenportaal geactiveerd voor een gebruiker met dit emailadres.',
          ERROR_EMAIL_USED_FOR_MULTIPLE_CONTACTS: 'Er bestaan meerdere contacten met hetzelfde e-mailadres. Gelieve deze samen te voegen alvorens het klantenportaal voor dit contact te activeren.'
        }
        this.activating = false
        return errorModal(errorCodes[error.response?.data?.code?.[0]] || 'Fout bij het activeren van het klantenportaal.')
      }
    },
    async setGdprGreen () {
      const payload = {
        id: this.contact.id,
        consent_asked: true,
        global_unsubscribe: false
      }
      this.settingGdprGreen = true
      const response = await this.saveContact(payload)
      this.settingGdprGreen = false
      this.setContactConsentAsked(true)
      this.setContactGlobalUnsubscribe(false)
      return response
    },
    async fetchViesDetails () {
      try {
        this.viesDataLoading = true
        const response = await getContactVies(this.contact.id)
        this.viesData = response.data
        this.$refs.viesModal.show()
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het laden van de details van het ondernemingsnummer')
      } finally {
        this.viesDataLoading = false
      }
    }
  }
}
</script>
