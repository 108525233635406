<template>
  <div>
    <!-- We allow adding duplicates, hence track-by="" (business requirement): https://dewaele.atlassian.net/browse/DEW-6665?focusedCommentId=89121 -->
    <FormulateInput
      v-model="activeTags"
      type="tagselect"
      :allow-remove="false"
      :show-tags-on-close="false"
      :block-keys="['Delete', 'Backspace']"
      :exclude-automated-tags="true"
      track-by=""
      label="Tags beheren"
      tag-placeholder=""
      tag-position="bottom"
      placeholder="Zoek naar een tag"
      outer-class="tw-my-0"
      @select="selectTag"
      @tag="openNewTagModal"
    >
      <template #label>
        <label
          for="tags_include"
          class="formulate-label tw-flex tw-justify-end"
        >
          <button
            type="button"
            class="tw-text-xs"
            @click="showTagsOverview"
          >
            Overzicht <i class="fas fa-info-circle" />
          </button>
        </label>
      </template>
    </FormulateInput>

    <div class="tw-py-2 tw-px-1 tw-grid md:tw-grid-cols-2 tw-gap-4">
      <div class="tw-mb-4">
        <h4 class="tw-my-1">Actieve tags</h4>
        <div
          :class="[
            { 'tw-border tw-border-dashed tw-border-gray-cc': highlightActive },
            'tag-container'
          ]"
          @dragover.prevent
          @dragenter.prevent.self="highlightActive = true"
          @dragleave.prevent.self="highlightActive = false"
          @drop.prevent="onDrop($event, 'active'); highlightActive = false;"
        >
          <Tooltip
            v-for="(tag, index) in activeTags"
            :key="`active_${tag.id}`"
            draggable="true"
            @dragstart.native="onDragStart($event, tag, index, 'active')"
          >
            <router-link
              :to="{ name: 'ContactTagDetails', params: { id: tag.tag_id } }"
              :style="`
                background: ${tag.color};
                color: ${getTagTextColor(tag.color)};
              `"
              class="tag tw-cursor-move tw-block"
            >
              {{ tag.name }}
            </router-link>
            <template #popper>
              <div class="tw-text-center">
                <span v-if="tag.updated_by" class="tw-font-semibold tw-block">
                  {{ tag.updated_by.first_name }} {{ tag.updated_by.last_name }}
                </span>
                <span class="tw-block">{{ formatDateTime(tag.started_on) }}</span>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>

      <div>
        <h4 class="tw-my-1">Inactieve tags</h4>
        <div
          :class="[
            { 'tw-border tw-border-dashed tw-border-gray-cc': highlightInactive },
            'tag-container'
          ]"
          @dragover.prevent
          @dragenter.prevent.self="highlightInactive = true"
          @dragleave.prevent.self="highlightInactive = false"
          @drop.prevent="onDrop($event, 'inactive'); highlightInactive = false;"
        >
          <Tooltip
            v-for="(tag, index) in inactiveTags"
            :key="`inactive_${tag.id}`"
            draggable="true"
            @dragstart.native="onDragStart($event, tag, index, 'inactive')"
          >
            <router-link
              :to="{ name: 'ContactTagDetails', params: { id: tag.tag_id } }"
              class="tag tw-cursor-move tw-bg-gray-cc !tw-text-tg-color tw-block"
            >
              {{ tag.name }}
            </router-link>
            <template #popper>
              <div class="tw-text-center">
                <span v-if="tag.updated_by" class="tw-font-semibold tw-block">
                  {{ tag.updated_by.first_name }} {{ tag.updated_by.last_name }}
                </span>
                <span class="tw-block">{{ formatDateTime(tag.started_on) }}</span>
                <span class="tw-block">{{ formatDateTime(tag.ended_on) }}</span>
              </div>
            </template>
          </Tooltip>
        </div>
      </div>
    </div>
    <AddNewTagModal ref="newTagModal" @tagCreated="newTagCreated" />
    <ContactTagsOverview
      ref="overview"
      :exclude-automated-tags="true"
      @tagSelected="assignSelectedTag"
    />
  </div>
</template>

<script>
import { Tooltip } from 'floating-vue'
import { getTagTextColor, formatDateTime } from '@/utils/helpers'

import AddNewTagModal from '@/components/contacts/AddNewTagModal'
import ContactTagsOverview from '@/components/contacts/ContactTagsOverview'

import {
  getContactTagsForContactId,
  addTagToContact,
  updateContactTag
} from '@/services/contacts'

export default {
  name: 'ContactTagManager',
  components: {
    Tooltip,
    AddNewTagModal,
    ContactTagsOverview
  },
  props: {
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      searching: false,
      tags: [],
      activeTags: [],
      inactiveTags: [],
      highlightActive: false,
      highlightInactive: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    getTagTextColor,
    formatDateTime,
    openNewTagModal (name) {
      this.$refs.newTagModal.show(name)
    },
    onDragStart (event, tag, index, state) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('tagIndex', index)
      event.dataTransfer.setData('tagState', state)
      event.dataTransfer.setData('tag', JSON.stringify(tag))
    },
    onDrop (event, state) {
      this.showActive = false
      const tagIndex = event.dataTransfer.getData('tagIndex')
      const tagState = event.dataTransfer.getData('tagState')
      const tag = JSON.parse(event.dataTransfer.getData('tag'))

      if (tagState === state) return // Do not allow dropping in the same state box
      try {
        if (state === 'active') {
          // We change the started_on to reflect the current time to show that the tag is updated, without fetching it again from the API
          this.activeTags.push({ ...tag, started_on: new Date() })
          this.inactiveTags.splice(tagIndex, 1)
          return this.changeTagState(tag.id, true) // is_active: true
        } else {
          // We change the ended_on to reflect the current time to show that the tag is updated, without fetching it again from the API
          this.inactiveTags.push({ ...tag, ended_on: new Date() })
          this.activeTags.splice(tagIndex, 1)
          return this.changeTagState(tag.id, false) // is_active: false
        }
      } catch (error) {
        // Add back the tag if the change state operation fails
        state === 'active'
          ? this.inactiveTags.splice(tagIndex, 0, tag)
          : this.activeTags.splice(tagIndex, 0, tag)
      }
    },
    newTagCreated (tag) {
      this.activeTags.push(tag)
      return this.selectTag(tag)
    },

    async init () {
      const [activeTags, inactiveTags] = await Promise.all([
        this.loadTagsForContact(this.contactId, { is_active: true }), // Active tags
        this.loadTagsForContact(this.contactId, { is_active: false }) // In-active tags
      ])
      this.activeTags = activeTags
      this.inactiveTags = inactiveTags
      return [activeTags, inactiveTags]
    },
    async loadTagsForContact (contactId, params) {
      const response = await getContactTagsForContactId(contactId, params)
      return response.data?.results || []
    },
    async selectTag (tag) {
      try {
        const response = await addTagToContact(this.contactId, { tag: tag.id })
        this.activeTags = await this.loadTagsForContact(this.contactId, { is_active: true })
        return response
      } catch (error) {
        this.activeTags = this.activeTags.filter(activeTag => activeTag.id !== tag.id)
      }
    },
    async changeTagState (tagId, is_active) {
      await updateContactTag(this.contactId, tagId, { is_active })
      const tags = await this.loadTagsForContact(this.contactId, { is_active })
      return tags
    },
    showTagsOverview () {
      this.$refs.overview.show()
    },
    async assignSelectedTag (eventData) {
      const response = await this.selectTag(eventData.tag)
      return response
    }
  }
}
</script>

<style scoped>
.tag-container {
  @apply tw-py-1 tw-flex tw-flex-wrap tw-gap-1 tw-h-full tw-min-h-[50px] tw-content-start;
}
</style>
