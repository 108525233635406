<template>
  <Modal ref="modal" :title="title">
    <div>
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-2 control-label">Type relatie</label>
          <div class="col-sm-4">
            <select class="form-control" v-model="link.type">
              <option :key="type.id" :value="type.id" v-for="type in linkTypes">{{ type.name }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Notities</label>
          <div class="col-sm-6">
            <textarea
              v-model="link.notes"
              placeholder="Hier kan je een notitie opnemen voor deze relatie"
              rows="3"
              class="form-control"
            />
          </div>
        </div>
      </form>
      <h2>Contact</h2>
      <em>
        <small>Selecteer een bestaand contact of maak een nieuw contact aan.</small>
      </em>
      <ContactGetOrCreate ref="contactPicker" />
    </div>
    <div slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        :disabled="creatingLink"
        @click="createLinkHandler"
      >
        <i v-if="creatingLink" class="fas tw-mr-1 fa-spinner-third fa-spin" />
        Opslaan
      </button>
      <button
        type="button"
        data-dismiss="modal"
        class="btn btn-white"
      >
        Sluiten
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/iam/Modal'
import ContactGetOrCreate from '@/components/contacts/ContactGetOrCreate'

import { mapActions } from 'vuex'
import { errorModal, successModal } from '@/modalMessages'
import { getContactById, createContactsLink, updateContactsLink, getContactLinkTypes } from '@/services/contacts'

export default {
  name: 'ContactLinkModal',
  components: { ContactGetOrCreate, Modal },
  data () {
    return {
      edit: false,
      link: {
        type: 1,
        to_relation: null,
        notes: ''
      },
      linkTypes: [],
      creatingLink: false
    }
  },
  computed: {
    title () {
      return this.edit ? 'Relatie bewerken' : 'Relatie aanmaken'
    }
  },
  created () {
    this.loadLinkTypes()
  },
  methods: {
    ...mapActions('contacts', [
      'saveContact',
      'createLink',
      'loadLinksForContactById'
    ]),

    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },

    async loadLinkTypes () {
      const response = await getContactLinkTypes()
      this.linkTypes = response.data?.results
      return response
    },

    newLink () {
      this.edit = false
      this.clear()
      this.show()
    },
    editLink (link) {
      this.edit = true
      const { id, type, to_relation, notes } = link
      this.link = {
        id,
        type: type.id,
        to_relation,
        notes
      }
      this.$refs.contactPicker.select(to_relation)
      this.show()
    },
    clear () {
      this.link = {
        type: 1,
        to_relation: null,
        notes: ''
      }
      this.$refs.contactPicker.clear()
    },

    validateToRelation (toRelation) {
      const { first_name, last_name, company_name, phone, mobile, email, type } = toRelation
      const nameExists = first_name || last_name || company_name
      // A company contact does not require either a phone, mobile or email
      const contactMethodExists = type === 'B' || (phone || mobile || email)
      return nameExists && contactMethodExists
    },
    validateAddingSelf (fromRelationId, toRelationId) {
      // Validate if not adding oneself as its to relation
      if (fromRelationId === toRelationId) {
        return true
      }
      return false
    },
    async validateLinkExists (link) {
      const response = await getContactById(link.from_relation)
      const fromContact = response.data
      let linkExists = false
      fromContact.links.forEach((linkObj) => {
        if (linkObj.type.id === link.type && linkObj.relation === link.to_relation) {
          linkExists = true
        }
      })
      return linkExists
    },

    /** Checks if we are adding a relation type when already have the inverse relation for the same contact */
    async validateCreatingLinkWhenInversePresent (link) {
      const response = await getContactById(link.from_relation)
      const fromContact = response.data
      let inverseLinkType = this.linkTypes.filter(linkType => linkType.id === link.type)[0]
      inverseLinkType = inverseLinkType.inverse.id

      let inverseLinkTypeExists = false
      fromContact.links.forEach(linkObj => {
        if (linkObj.type.id === inverseLinkType && linkObj.relation === link.to_relation) {
          inverseLinkTypeExists = true
        }
      })

      return inverseLinkTypeExists
    },

    async createLinkHandler () {
      try {
        this.creatingLink = true

        const link = JSON.parse(JSON.stringify(this.link))
        const toRelation = this.$refs.contactPicker.getContact()
        const fromRelationId = parseInt(this.$route.params.id)
        const toRelationId = toRelation.id

        const validRelation = this.validateToRelation(toRelation)
        if (!validRelation) {
          return errorModal('Kies een bestaand contact of vul de voornaam, naam en minstens 1 communicatiemethode in, om een nieuw contact toe te voegen.')
        }

        const AddingSelf = this.validateAddingSelf(fromRelationId, toRelationId)
        if (AddingSelf) {
          this.clear()
          return errorModal('Men kan niet zichzelf opgeven als een relatie')
        }

        link.to_relation = toRelationId
        link.from_relation = fromRelationId

        const contactChanged = this.link.to_relation?.id !== toRelationId

        // If new contact save the contact first
        if (!toRelationId) {
          const newRelation = await this.saveContact(toRelation)
          link.to_relation = newRelation.id
        } else if (contactChanged) {
          const linkExists = await this.validateLinkExists(link)
          if (linkExists) return errorModal('Er bestaat al een relatie met het opgegeven relatietype')
        }
        const response = this.edit ? await updateContactsLink(link) : await createContactsLink(link)
        await this.loadLinksForContactById(fromRelationId)
        successModal('De relatie is aangemaakt')
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Kan relatie niet aanmaken, gelieve opnieuw te proberen')
      } finally {
        this.creatingLink = false
      }
    }
  }
}

</script>
