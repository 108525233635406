<template>
  <Panel :is-collapsible="false" title="Zoekopdrachten">
    <div slot="toolbar-buttons">
      <button
        type="button"
        :title="hmodhCreationButtonTitle"
        :disabled="hmodhCreationDisabled"
        class="tw-ml-2 btn btn-primary btn-xs"
        @click="newHmodh"
      >
        <i class="glyphicon glyphicon-plus-sign" /> Nieuw
      </button>
    </div>

    <DataTable
      :headers="headers"
      :can-search="true"
      :infinite-scroll="true"
      :infinite-key="identifier"
      v-bind="hmodhs"
      class="tw--m-5 tw-shadow-none"
      @infiniteLoad="infiniteLoad"
      @filter="refreshHmodhs"
      @changedOrder="refreshHmodhs"
    >
      <template #item-active_status="{ item }">
        <!-- hmodh_status 1: active !-->
        <!-- hmodh_status 2: archived !-->
        <span
          :class="['fa fa-circle', isHmodhActive(item) ? 'tw-text-green-500' : isHmodhArchived(item) ? 'tw-text-error' : 'tw-text-warning']"
          :title="getArchivalInformation(item)"
        />
      </template>
      <template #item-status_display="{ item }">
        {{ item.status_display }}
      </template>
      <template #item-created_by="{ item }">
        <span>
          {{
            item.created_by
              ? `${item.created_by.first_name} ${item.created_by.last_name}`
              : 'Aangemaakt door contact via klantenportaal'
          }}
        </span>
      </template>
      <template #item-niches="{ item }">
        <span v-for="(niche_id, index) in item.niches" :key="niche_id">
          <template v-if="index > 0"> | </template>
          {{ NICHES[niche_id] }}
        </span>
      </template>
      <template #item-properties_refreshed_on="{ item }">
        {{ item.properties_refreshed_on | datetime }}
      </template>

      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            v-if="isHmodhActive(item)"
            title="Bewerk HMODH"
            class="action tw-bg-warning"
            @click="editHmodh(item)"
          >
            <i :class="[loadingHmodhDetails ? 'fa fa-spinner fa-spin' : 'far fa-pencil']" />
          </button>
          <!-- hmodh_status 3: awaiting activation !-->
          <button
            v-if="!isHmodhAwaitingActivation(item)"
            title="Verzonden HMODH's"
            class="action tw-bg-success"
            @click="showHmodhMailings(item)"
          >
            <i :class="[loadingHmodhMailings === item.id ? 'fa fa-spinner fa-spin' : 'far fa-envelope']" />
          </button>
          <button
            v-if="isHmodhActive(item)"
            title="Resterende panden"
            class="action tw-bg-primary"
            @click="showHmodhProperties(item)"
          >
            <i class="far fa-eye" />
          </button>
          <button
            v-if="!isHmodhAwaitingActivation(item)"
            :title="isHmodhActive(item) ? 'Archiveren' : 'Heractiveren'"
            :class="['action', isHmodhActive(item) ? 'tw-bg-error' : 'tw-bg-primary']"
            @click="toggleHmodh(item)"
          >
            <i :class="[isHmodhActive(item) ? 'far fa-archive' : 'fa fa-rotate-left']" />
          </button>
        </div>
      </template>
    </DataTable>

    <HmodhEditCreate
      ref="editCreate"
      :contact="contact"
      @hmodhCreated="refreshHmodhs"
      @hmodhUpdated="refreshHmodhs"
    />
    <HmodhPropertiesModal ref="hmodhPropertiesModal" />
    <HmodhMailings ref="mailingsModal" />
  </Panel>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

import Panel from '@/components/iam/Panel'
import DataTable from '@/components/DataTable'

import HmodhCriteria from '@/components/contacts/hmodh/Criteria'
import HmodhEditCreate from '@/components/contacts/hmodh/HmodhEditCreate'
import HmodhPropertiesModal from '@/components/contacts/hmodh/HmodhPropertiesModal'
import HmodhMailings from '@/components/contacts/hmodh/HmodhMailings'

import { NICHES } from '@/forms/selectOptions'
import { formatDateNlBe } from '@/utils/helpers'
import { questionModal, errorModal } from '@/modalMessages'
import { getHmodhs, removeHmodh, getHmodhById, updateHmodh } from '@/services/hmodh'

const blankHmodh = {
  types: [],
  niches: [],
  bathrooms_max: null,
  bathrooms_min: null,
  bedrooms_max: null,
  bedrooms_min: null,
  build_year_from: null,
  build_year_till: null,
  garage: 0,
  garden: 0,
  is_newly_built: false,
  pets_allowed: 0,
  price_max: null,
  price_min: null,
  property_type: 3,
  restrooms_max: null,
  restrooms_min: null,
  is_investment: false,
  return_on_investment_min: null,
  return_on_investment_max: null,
  sale_with_shares: 2,
  is_rented: false,
  source: 1,
  status: 2,
  surface_plot_max: null,
  surface_plot_min: null,
  surface_trading_max: null,
  surface_trading_min: null,
  surface_livable_min: null,
  surface_livable_max: null,
  swimming_pool: 0,
  terrace: 0,
  sea_view: 0,
  elevator: 0,
  light_street: 0,
  rolling_bridge: 0,
  furnished: 0,
  bike_storage: 0,
  free_height_min: null,
  free_height_max: null,
  loading_docks_min: null,
  loading_docks_max: null,
  kva_min: null,
  kva_max: null,
  usable_office_min: null,
  usable_office_max: null,
  usable_storage_min: null,
  usable_storage_max: null,
  zipcodes: [],
  geo_search: [],
  building_types: [],
  sales_concept: 0,
  is_luxury: false,
  parking_spots_min: null,
  parking_spots_max: null
}

export default {
  name: 'HmodhPanel',
  components: {
    DataTable,
    HmodhPropertiesModal,
    Panel,
    HmodhMailings,
    HmodhEditCreate
  },
  props: {
    contact: {
      type: Object
    }
  },
  constants: {
    NICHES
  },
  data () {
    return {
      identifier: uniqueId('hmodhs_'),
      // The object communications includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      hmodhs: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      hmodhParams: {},
      hmodhMails: [],
      loadingHmodhMailings: false,
      loadingHmodhDetails: false
    }
  },
  computed: {
    headers () {
      return [
        { value: 'active_status', text: 'Actief', orderable: true, orderByKey: 'id' },
        { value: 'status_display', text: 'Status' },
        { value: 'niches', text: 'Niche' },
        { value: 'comment_internal', text: 'Extra informatie HMODH' },
        { value: 'criteria', text: 'Criteria', renderComponent: HmodhCriteria },
        { value: 'properties_refreshed_on', text: 'Laatste matching' },
        { value: 'created_by', text: 'Aangemaakt door' },
        { value: 'actions', text: 'Actie' }
      ]
    },
    hmodhCreationDisabled () {
      return this.contact?.global_unsubscribe
    },
    hmodhCreationButtonTitle () {
      if (this.hmodhCreationDisabled) return 'Het contact heeft een rode GDPR-status'
      return ''
    }
  },
  methods: {
    isHmodhActive (hmodh) {
      return hmodh.hmodh_status === 1
    },
    isHmodhArchived (hmodh) {
      return hmodh.hmodh_status === 2
    },
    isHmodhAwaitingActivation (hmodh) {
      return hmodh.hmodh_status === 3
    },
    newHmodh () {
      this.$refs.editCreate.hmodh = { ...blankHmodh }
      this.$refs.editCreate.show()
    },

    showHmodhProperties (hmodh) {
      this.$refs.hmodhPropertiesModal.hmodh = hmodh
      this.$refs.hmodhPropertiesModal.show()
    },
    showHmodhMailings (hmodh) {
      try {
        this.loadingHmodhMailings = hmodh.id
        this.$refs.mailingsModal.show(hmodh.id)
      } catch (error) {
        console.error(error)
        errorModal('Kan Hou me op de hoogte mails niet tonen, gelieve opnieuw te proberen')
      } finally {
        this.loadingHmodhMailings = null
      }
    },

    getArchivalInformation (hmodh) {
      if (!hmodh.archived_on) return ''
      const formattedDateTime = formatDateNlBe(hmodh.archived_on, 'dd-MM-yyyy HH:mm')
      if (!(hmodh.archived_by_collaborator || hmodh.archived_by_contact)) return `Gearchiveerd op ${formattedDateTime} door IAM`
      const archiver = hmodh.archived_by_collaborator
        ? `${hmodh.archived_by_collaborator.first_name} ${hmodh.archived_by_collaborator.last_name}`
        : `${hmodh.archived_by_contact.display_name}`
      return `Gearchiveerd op ${formattedDateTime} door ${archiver}`
    },
    refreshHmodhs (payload = { params: {} }) {
      this.hmodhs = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.hmodhParams = payload.params
      this.identifier = uniqueId('refreshed_hmodhs_')
    },

    async fetchHmodhs (payload) {
      const response = await getHmodhs(payload)
      return response.data
    },
    async infiniteLoad ($infinite, next) {
      try {
        const payload = {
          params: {
            // By default, order in descending order of creation
            ordering: '-id',
            contact: this.contact?.id,
            ...this.hmodhParams
          }
        }
        if (next) payload.url = next
        const hmodhs = await this.fetchHmodhs(payload)
        const results = [...this.hmodhs.results, ...hmodhs.results]
        this.hmodhs = { ...hmodhs, results }
        if (results.length) $infinite.loaded()
        if (!hmodhs.next) $infinite.complete()
        return hmodhs
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async toggleHmodh (hmodh) {
      const response = this.isHmodhActive(hmodh)
        ? await this.archiveHmodh(hmodh)
        : await this.reactivateHmodh(hmodh)

      this.refreshHmodhs()
      return response
    },
    async archiveHmodh (hmodh) {
      try {
        const result = await questionModal('Weet je zeker dat je deze HMODH wil archiveren?')
        if (!result.value) return

        const response = await removeHmodh(hmodh.id)
        return response
      } catch (error) {
        console.error(error)
        await errorModal('Kan hou me op de hoogte niet verwijderen, gelieve opnieuw te proberen')
      }
    },
    async reactivateHmodh (hmodh) {
      try {
        // `hmodh_status 1 is active`
        const response = await updateHmodh(hmodh.id, { hmodh_status: 1 })
        return response
      } catch (error) {
        console.error(error)
        await errorModal('Kan hou me op de hoogte niet opnieuw activeren, gelieve opnieuw te proberen')
      }
    },
    async editHmodh (hmodh) {
      try {
        this.loadingHmodhDetails = true
        const response = await getHmodhById(hmodh.id)
        this.$refs.editCreate.hmodh = response.data
        this.$refs.editCreate.show()
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het laden van de HMODH.')
      } finally {
        this.loadingHmodhDetails = false
      }
    }
  }
}
</script>
